import { useMemo } from 'react'

import useSession from 'hooks/useSession'
import useSystemConfiguration from 'hooks/useSystemConfiguration'

const useExposee = () => {
  const session = useSession()
  const systemConfiguration = useSystemConfiguration()

  const exposee = useMemo(
    () => ({
      anonId: session.anonId || '',
      userId: session.user?.id || null,
      countryCode: systemConfiguration?.userCountry || '',
    }),
    [session.anonId, session.user?.id, systemConfiguration?.userCountry],
  )

  return exposee
}

export default useExposee
