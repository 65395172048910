'use client'

import { CurrencyAmountModel } from 'types/models'
import { Screen } from 'constants/tracking/screens'

import ElectronicsVerificationModalBuyer from './ElectronicsVerificationBuyerModal'
import ElectronicsVerificationSellerModal from './ElectronicsVerificationSellerModal'

type Props = {
  show: boolean
  isViewingOwnItem: boolean
  onClose: () => void
  verificationFee?: CurrencyAmountModel
  screen: Screen
}

const ElectronicsVerification = (props: Props) => {
  const { isViewingOwnItem, ...rest } = props

  if (isViewingOwnItem) {
    return <ElectronicsVerificationSellerModal {...rest} />
  }

  return <ElectronicsVerificationModalBuyer {...rest} />
}

export default ElectronicsVerification
